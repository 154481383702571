import "swiper/swiper.min.css";

import { Button, ButtonBase, useMediaQuery } from "@mui/material";
import appstore from "assets/appstore.svg";
import linesBig from "assets/big-lines.svg";
import like from "assets/Bitmap (16).png";
import eclipse from "assets/eclipse.svg";
import star from "assets/H45Bm0002Hands008 (1).png";
import playstore from "assets/playstore.svg";
import qrCode from "assets/qr-code.svg";
import Icon from "components/Icon";
import Slider from "components/Slider";
import { colors } from "constants/colors";
import React from "react";
import { Link } from "react-router-dom";

const Guide = React.forwardRef((props, ref) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [texts, setText] = React.useState("");
  const text = [
    "Нэвтрэх",
    "Тасалбар",
    "Азын тоо сонгох",
    "Megaball сонгох",
    "Сонгосон тоо",
    "Тасалбарын үнэ",
    "Сагсанд нэмэх",
    "Худалдан авах",
    "Багц худалдан авалт",
    "Тогтмол худалдан авалт",
  ];
  const guideNumbers = ["1.", "2.", "2.1", "2.2", "2.3", "2.4", "2.5", "3.","4.","5."];
  const guideText = [
    "Бид Hipay ХХК-тай албан ёсны хамтын ажиллагаа эхлүүлж, Hipay аппликейшнаар дамжуулан тасалбар худалдаалж байна",
    "Та дэлгэцэн дээр байрлах бөглөх товч дээр дарна уу",
    "Бөглөх товчлуур дээр дарснаар тасалбар хэсэг нээгдэх ба 1-45 хүртэлх тооноос дурын тоогоо сонгоно",
    "Энэхүү хэсэгт байрлах 5 тооноос нэгийг сонгоно",
    "Таны сонгосон тоо дээд хэсэгт харагдана",
    "Хонжворын дүнгээрээ ялгаатай 2 үнийн дүн бүхий тасалбараас авах ба нэгээс олон сонголт хийх боломжтой юм",
    "Бөмбөлөг болон худалдан авах үнийн дүн сонгосны дараа сагсанд нэмэх товч дарж сагсална",
    "Сагсанд нэмсэн нийт тасалбаруудаа худалдаж аван төлбөрөө төлснөөр таны тасалбар баталгаажна",
    "Та х5 , х10 гэсэн товч дээр дарснаар тасалбараа 5 болон 10-аар багцалж авах боломжтой.",
    "Тогтмол худалдан авалт гэсэн товч дээр дарснаар өөрийн сонгосон тоог ирээдүйн тохиролд урьдчилан бүртгүүлэх боломжтой.",
  ];

  const isPhone = useMediaQuery("(max-width: 768px)");

  return (
    <div
      className="relative overflow-hidden w-full"
      style={{ background: `url(${linesBig})` }}
      ref={ref}
    >
      <div className="flex bg-blue justify-center pt-[427px] md:pt-16 px-4 relative">
        <div className="flex flex-1 justify-between max-w-[1268px] md:flex-col md:items-center">
          <div className="flex flex-1 justify-start items-start">
            <div className="z-10 flex flex-col justify-start normal-case text-black0">
              <p className="font-bold text-4xl md:text-3xl">
                Хэрхэн{"\n"}
                <span className="text-amber-400">оролцох заавар</span>
              </p>
              <p>
                <span className="normal-case text-3xl text-black0 z-10 text-center my-6 hidden md:block">
                  {guideNumbers[activeIndex % guideNumbers.length]}
                </span>
              </p>
              <div className="md:hidden">
                <div className="flex justify-between">
                  <div>
                    <p className="normal-case text-4xl text-black0 z-10 ">
                      {guideNumbers[activeIndex % guideNumbers.length]}
                    </p>
                    <ButtonBase
                      style={{
                        background: "#FF5C00",
                        padding: 10,
                        borderRadius: 24,
                      }}
                      onClick={() => {
                        setActiveIndex((p) => p + 1);
                        setText(text[activeIndex % text.length]);
                      }}
                    >
                      <p className="text-lg font-bold font-sans flex flex-row px-12">
                        {text[activeIndex % text.length]}
                      </p>
                    </ButtonBase>
                  </div>
                </div>
                <p className="z-10 pt-3 font-medium text-xl">
                  {guideText[activeIndex % guideText.length]}
                </p>
              </div>
            </div>
          </div>

          <div className="relative flex flex-1 justify-end">
            <div className=" flex items-center ">
              <Button
                onClick={() =>
                  setActiveIndex((p) => {
                    if (p === 0) return 0;
                    return p - 1;
                  })
                }
                style={{ borderRadius: 24, padding: 2 }}
              >
                <Icon
                  icon="ic20-chevron-left"
                  size={isPhone ? 24 : 40}
                  color={colors.black0}
                  className="align-middle z-10"
                />
              </Button>
              <div className="swiper-wrapper">
                <Slider activeIndex={activeIndex} />
                {/*<img alt="" src={screen2} className="z-10 relative" />*/}

                <img
                  alt=""
                  src={eclipse}
                  className="z-0 absolute right-[-73%] bottom-[-14%] pointer-events-none "
                />
              </div>
              <Button
                onClick={() => setActiveIndex((p) => p + 1)}
                style={{ borderRadius: 24, padding: 2 }}
              >
                <Icon
                  icon="ic20-chevron-right"
                  size={isPhone ? 24 : 40}
                  color={colors.black0}
                  className="align-middle z-10"
                />
              </Button>
            </div>
          </div>

          <div className="hidden md:block my-6 max-w-[400px] z-10">
            <div className="flex justify-between">
              <ButtonBase
                style={{
                  background: "#FF5C00",
                  padding: 10,
                  borderRadius: 24,
                  width: "100%",
                }}
                onClick={() => {
                  setActiveIndex((p) => p + 1);
                  setText(text[activeIndex % text.length]);
                }}
              >
                <p className="text-lg font-bold flex flex-row text-2xl text-white">
                  {text[activeIndex % text.length]}
                </p>
              </ButtonBase>
            </div>
            <p className="z-10 pt-3 font-medium text-white text-base">
              {guideText[activeIndex % guideText.length]}
            </p>
          </div>
        </div>

        <img
          alt=""
          src={like}
          className="h-40 absolute top-[580px] left-1/3 z-20 md:top-[36px] md:left-2/3 md:h-20"
        />
      </div>
      <div className="flex bg-blue justify-center py-[150px] px-4 relative md:pb-8 md:pt-24">
        <div className="flex flex-1 justify-between max-w-[1268px] md:max-w-[400px]">
          <div className="flex flex-1 flex-col items-start">
            <div className="z-10">
              <p className="text-black10 font-bold text-[48px] mb-6">
                Аппаа татаад{"\n"}ялагч болоорой!
              </p>
              <p className="text-base text-white font-medium">
                Та өөрийн сонгосон тоогоороо ялагч болж, таны хийсэн хөрөнгө
                оруулалтаар хүн бүр ЯЛАГЧ болно
              </p>
              <div className="flex flex-row flex-wrap gap-3 md:justify-center z-10 mt-6">
                <Link
                  to={"/"}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open("https://apple.co/37kcjSf");
                  }}
                >
                  <img alt="" src={appstore} className="md:h-[50px]" />
                </Link>
                <Link
                  to={"/"}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "https://play.google.com/store/apps/details?id=mn.highpaymentsolutions.hipay_wallet_v2"
                    );
                  }}
                >
                  <img alt="" src={playstore} className="md:h-[51px]" />
                </Link>
              </div>
            </div>
          </div>
          <div className="md:hidden z-10 flex justify-center flex-1">
            <img alt="" src={qrCode} />
          </div>
        </div>

        <img
          alt=""
          src={star}
          className="h-40 absolute top-[32px] z-10 left-1/3 md:left-2/3 md:h-20"
        />
      </div>
      <img
        alt=""
        src={linesBig}
        className="justify-items-end right-0 absolute bottom-0 h-3/4 z-0 pointer-events-none"
      />
    </div>
  );
});
export default Guide;
